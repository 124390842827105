const columnData = {
  "Account Management": [
      "General Account Management Visit",
      "Email",
      "New Shop Installation",
      "Device Upgrade",
      "Caller ID",
      "Cancellation",
      "ROC (Risk Of Churn)",
      "Complaint",
      "Account Closed Down",
      "Marketing Visit",
      "Flyer Drop",
      "COO",
      "Poster Drop"
  ],
  "Accounts Related": [
      "Account on Hold",
      "Direct Debit Cancelled",
      "Direct Debit Failed",
      "Payment Issue",
      "Querying Charges",
      "Charges Refunded",
      "Statement Requested",
      "Package Change",
  ],
  "Back Office": [
      "Welcome Call",
      "Welcome Email",
      "Email",
      "Customer Refund",
      "Post Installation Call",
      "Call Back Requested",
      "Account Manager Visit Requested",
      "Temp Offline",
      "Rejected Orders",
      "Cancellation Request",
      "Complaint",
  ],
  "I.T. Related": [
      "App Issue",
      "Device Issue ePOS",
      "Device Issue V2",
      "Tech Issue",
      "Bug",
      "SSL",
  ],
  "Marketing": [
      {name: "Flyer", value: 'flyer'},
      {name: "Poster", value: 'poster'},
      {name: "Menu Link", value: 'menu link'},
      {name: "Google Business Page Requested", value: 'GOOGLE BUSINESS LISTING ATTEMPTED'},
      {name: "Google Business Page Claimed", value: 'GOOGLE BUSINESS LISTING CLAIMED'},
      {name: "Google Business Page Suggested Edit", value: 'GOOGLE BUSINESS LISTING SUGGESTED EDIT'},
      {name: "Google Ad", value: 'PPC GOOGLE'},
      'Google Post',
      {name: "Facebook Ad", value: 'PPC FACEBOOK'},
      {name: "Facebook Post", value: 'facebook'},
      'Push Notifications',
      {name: "SMS Sent", value: 'sms'},
      'Other Notes',
  ],
  "Menu": [
      "New Menu",
      "Menu Update"
  ],
};

export default columnData;