const agentOverviewColumns = [
  {name: 'Agent Name', value: 'agent_name'},
  {name: 'Region', value: 'region'},
  {name: 'Shops', value: 'shop_count'},
  {ref: 'devices', value: [
    {name: 'V2 or V2s', value: 'v2', tags: [{tagText: 'DEVICES'}]},
    {name: 'T2s', value: 'epos', tags: [{tagText: 'ePOS'}]},
    {name: 'Caller Id', value: 'active_caller_ids', tags: [{tagText: 'ePOS'}]},
    {name: 'Card Reader', value: 'total_card_readers', tags: [{tagText: 'ePOS'}]},
  ]},
  {ref: 'orders_epos', value: [
    {name: 'This Month', value: 'instore_this_month', tags: [{tagText: 'ePOS'}, {tagText: 'IN-STORE', tagType: 'secondary'}]},
    {name: 'Last Month', value: 'instore_last_month', tags: [{tagText: 'IN-STORE', tagType: 'secondary'}]},
    {name: 'This Month', value: 'phone_this_month', tags: [{tagText: 'PHONE', tagType: 'secondary'}]},
    {name: 'Last Month', value: 'phone_last_month', tags: [{tagText: 'PHONE', tagType: 'secondary'}]},
    {name: 'This Month', value: 'card_terminal_payments_this_month', tags: [{tagText: 'CARD READER', tagType: 'secondary'}]},
    {name: 'Last Month', value: 'card_terminal_payments_last_month', tags: [{tagText: 'CARD READER', tagType: 'secondary'}]},
  ]},
  {ref: 'orders', value: [
    {name: 'Total ', value: 'total', tags: [{tagText: 'ORDERS'}]},
    {name: 'Today', value: 'today'},
    {name: 'This Week', value: 'this_week'},
    {name: 'This Month', value: 'this_month'},
    {name: 'MoM', value: 'mom'},
    {name: 'YoY', value: 'yoy'},
    {name: 'Last Month', value: 'last_month'},
    {name: 'Prev Month', value: 'prev_month'},
    {name: 'Prev Prev Month', value: 'prev_prev_month'},
    {name: 'Last Month Last Year', value: 'last_month_last_year'},
  ]},
  {ref: 'status', value: [
    {name: 'Online ', value: 'shops_online', tags: [{tagText: 'STATUS'}]},
    {name: 'To Activate', value: 'shops_to_activate'},
    {name: 'Temp Offline', value: 'shops_temp_offline'},
    {name: 'Offline', value: 'shops_cancelled'},
  ]},
  {ref: 'numbers', value: [
    {name: 'Last 7 Days ', value: 'last_7_days', tags: [{tagText: 'NUMBERS', tagType: 'plum'}]},
    {name: 'This Month', value: 'this_month'},
    {name: 'Last Month', value: 'last_month'},
    {name: 'Customers', value: 'total_customers'},
    {name: 'Caller ID', value: 'caller_ids'},
    {name: 'Added Manually', value: 'added_manually'},
  ]},
  {ref: 'sms', value: [
    {name: 'Balance ', value: 'balance', tags: [{tagText: 'SMS'}]},
    {name: 'This Week', value: 'this_week'},
    {name: 'This Month', value: 'this_month'},
    {name: 'Last Month', value: 'last_month'},
  ]},
  {ref: 'marketing', value: [
    {name: 'G Listing', value: 'google_business_claimed', tags: [{tagText: 'LEVERS'}]},
    {name: 'Branding', value: 'stickers'},
    {name: 'SMS Sent', value: 'sms'},
    {name: 'Flyers', value: 'flyers'},
    {name: 'ePOS Primary', value: 'is_epos_primary'},
    {name: 'Posters', value: 'posters'},
    {name: 'FB Page', value: 'social'},
    {name: 'AP Card', value: 'apcard'},
    {name: 'Menu Link', value: 'menu_link'},
    {name: 'PPC', value: 'ppc'},
    {name: 'Email', value: 'email'},
    {name: 'Push Notifications', value: 'push_notification'},
  ]}
];

export default agentOverviewColumns;