import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.scss"
import { filter } from "lodash";

import {Admin} from '../../../fmo-api-sdk';
import JobHistoryModal from "../../modals/job-history"
import AddJobModal from "../../modals/add-job";
import AddLogModal from "../../modals/add-log";
import addedIconTruffle from "../../../img/addedIconNewTruffle.svg";
import TicketCard from "../../ticket-card";
import ButtonFilter from "../../button-filter";
import Button from "../../button";
import AddLog from "../../../img/addLog.svg";
import ExpandedLogModal from "../../modals/expanded-log";
import moment from "moment";
import PuffLoader from "react-spinners/PuffLoader";

const LogsHistory = ({ shopName }) => {

    const { id }: { id: string } = useParams();
    const [orders, setOrders] = useState<Admin.ShopJob[]>([]);
    const [filtered, setFiltered] = useState<Admin.ShopJob[]>([]);
    const [showOnly, setShowOnly] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentJob, setCurrentJob] = useState<Admin.ShopJob | null>(null);
    const [showJobModal, setShowJobModal] = useState<boolean>(false);
    const [showLogModal, setShowLogModal] = useState<boolean>(false);
    const [pinnedLogs, setPinnedLogs] = useState<Array<any>>([])
    const [selectedLog, setSelectedLog] = useState<any>({});
    const [autoGenJob, setAutoGenJob] = useState(false);

    useEffect(() => {
      fetchShopHistory();
    }, [id]);

    const fetchShopHistory = async() => {
      (async () => {
        setCurrentJob(null);
        const data = await Admin.getShopHistory(id, "logs-and-jobs") as Admin.ShopJob[];
        // FIX to make it so that the job_id property is set. Which is needed to show the timeline in the model.
        const jobs = data.map((d) => {
            const job = d;
            job['job_id'] = d.id;
            return job;
        })
        setOrders(jobs);
        setFiltered(dataValidation(data));
        setPinnedLogs(data.filter(job => job.pinned === '1'));
    })()
    }

    useEffect(() => {
        var temp = dataValidation(orders);
        var tempFiltered = filter(temp, o => {
            if (showOnly === "log") return (o.type === "log" && !o.description.includes("Google Business Scraper reported")) || o.title === 'New Shop' || o.type === "standard funds request" || o.type === "same day funds request";
            if (showOnly === "job") return (o.type !== "log" && o.type !== "marketing" && o.title !== 'New Shop' && o.type !== "standard funds request" && o.type !== "same day funds request") || (o.description.includes("Google Business Scraper reported") && o.title === 'Google Business Listing Lost');
            if (showOnly === "marketing") return o.type === "marketing" && !o.description.includes("Google Business Scraper reported");
            if (showOnly === "scraper") return o.description.includes("Google Business Scraper reported") && o.title.toLowerCase() !== 'google business listing lost';
            if (showOnly === "") return !(o.description.includes("Google Business Scraper reported") && (o.type === 'marketing' || o.type === 'log'));
        });

        setFiltered(tempFiltered);
    }, [showOnly, pinnedLogs])

    const dataValidation = logs => {
      const filterSMS = log => {
        if (log.title !== 'sms') {
          return true
        } else {
          var smsLogs = logs.filter(log => log.title === 'sms');
          var filteredSms = smsLogs.filter(sms => (moment(sms.created).format('DD/MM/YY HH') === moment(log.created).format('DD/MM/YY HH')));
          if (filteredSms.findIndex(el => el.id === log.id) !== 0) {
            return false
          }
          return true
        }
      }

      var temp = logs.filter((log, index) =>
        // remove duplicates
        log.type !== 'standard funds request' &&
        log.title !== "Google Business Listing Captured" &&
        filterSMS(log)
      );

      // remove pinned logs from the data
      temp = temp.filter(log => !pinnedLogs.includes(log))

      return [...pinnedLogs, ...temp];
    }

    const updateShow = str => {
        if (str === showOnly) setShowOnly("");
        else setShowOnly(str);
    }

    //prevent scrolling when modal is open
    const windowWidth = window.innerWidth;
    let mainContent = document.getElementById("overview-page");
    const preventScrolling = function (showModal, showJobModal, showLogModal) {
        if (showModal || showJobModal || showLogModal) {
            document.body.classList.add("no-scroll");
            if (windowWidth > 1200) {
            mainContent?.classList.add("jitter-fix");
            }
        }
        else {
            document.body.classList.remove("no-scroll");
            mainContent?.classList.remove("jitter-fix");
        }
    }

    preventScrolling(showModal, showJobModal, showLogModal);

    const setToggle = log => {
      if (pinnedLogs.includes(log)) {
        return () => setPinnedLogs(pinnedLogs.filter(pinnedLogs => pinnedLogs !== log))
      }
      return () => setPinnedLogs([...pinnedLogs, log])
    }

    const getOnClick = (job, autoGenJob) => {
      return () => {
        setAutoGenJob(autoGenJob)
        if (['log', 'marketing'].includes(job.type)) {
          setSelectedLog(job)
        } else {
          setCurrentJob(job)
        }
      }
    }

    return ( 
        <div id="job-history">
            <AddJobModal
                isOpen={showJobModal}
                data={{shopId: id, shopName: shopName, jobId: ""}}
                setClosed={jobUpdated => {
                  setShowJobModal(false)
                  jobUpdated && fetchShopHistory()
                }}
            />
            <AddLogModal
                isOpen={showLogModal}
                data={{shopId: id, shopName: shopName, jobId: ""}}
                setClosed={jobUpdated => {
                  setShowLogModal(false)
                  jobUpdated && fetchShopHistory()
                }}
            />
            <ExpandedLogModal
                isOpen={!!selectedLog.id}
                data={{logTitle: selectedLog.title === '' ? 'Log' : selectedLog.title, logContent: selectedLog.description, logCreated: selectedLog.created, logCreator: selectedLog?.username?.toUpperCase(), autoGenerated: autoGenJob}}
                setClosed={() => setSelectedLog({})}
            />
            <JobHistoryModal
                isOpen={Boolean(currentJob?.id)}

                data={{currentJob}}
                autoGenerated={autoGenJob}
                setClosed={jobUpdated => {
                  setCurrentJob(null)
                  jobUpdated && fetchShopHistory()
                }}
                timeline />
            
            <div className="header-row">
                <div style={{ display: 'flex' }}>
                    <div className="title">Timeline</div>
                    {/* <input style={{ background: `url(${filterIcon}) #fcfcfc no-repeat 20px center` }} type="text" placeholder="Filter by Type" /> */}
                </div>

                <div className="show-only show-only--full-size">
                    <div className="label">Filter</div>

                    <ButtonFilter buttonText={'All'} active={showOnly === ''} onClick={() => updateShow("")} />
                    <ButtonFilter buttonText={'Jobs'} active={showOnly === 'job'} onClick={() => updateShow("job")} />
                    <ButtonFilter buttonText={'Logs'} active={showOnly === 'log'} onClick={() => updateShow("log")} />
                    <ButtonFilter buttonText={'Marketing'} active={showOnly === 'marketing'} onClick={() => updateShow("marketing")} />
                    <ButtonFilter buttonText={'Scraper'} active={showOnly === 'scraper'} onClick={() => updateShow("scraper")} />
                </div>
                
            </div>

            {!filtered.length ? (
              <div className="spinner-border">
                <PuffLoader
                  size={75}
                  color={"#D82B31"}
                  loading={true}
                />
              </div>
            ) : (
              <div className="job-cards">
                  <div className="actions-wrapper">
                      <div className="section-title">
                          <div className="icon">
                              <img src={addedIconTruffle} alt="modal icon"/>
                          </div>
                          <h3>Actions</h3>
                      </div>
                      <p>You can easily add to <span className="highlight">{shopName.replace(/\s{2,}/g,' ').trim()}</span>'s timeline by using the buttons below.</p>
                      <div className="action-buttons">
                          <Button type={'primary'} buttonText={'Create Job'} onClick={() => setShowJobModal(true)} grow />
                          <Button type={'tertiary'} icon={AddLog} iconAltText={'Add log'} buttonText={'Add Log'} onClick={() => setShowLogModal(true)} grow />
                      </div>
                  </div>

                  <div className="show-only show-only--mobile-only">
                      <div className="label">Filter</div>

                      <ButtonFilter buttonText={'All'} active={showOnly === ''} onClick={() => updateShow("")} />
                      <ButtonFilter buttonText={'Jobs'} active={showOnly === 'job'} onClick={() => updateShow("job")} />
                      <ButtonFilter buttonText={'Logs'} active={showOnly === 'log'} onClick={() => updateShow("log")} />
                      <ButtonFilter buttonText={'Marketing'} active={showOnly === 'marketing'} onClick={() => updateShow("marketing")} />
                      <ButtonFilter buttonText={'Scraper'} active={showOnly === 'scraper'} onClick={() => updateShow("scraper")} />
                  </div>

                  {filtered.map((order, idx) => {
                    return <TicketCard key={idx} job={order} timeline completed={order.status === 'Completed' || order.type.includes('complete')} pinned={pinnedLogs.includes(order)} setPinned={setToggle(order)} onClick={getOnClick} />
                  })}

              </div>
            )}
        </div>

    );
}

export default LogsHistory;
