import Modal from "react-modal";
import "./index.scss";
import xIcon from "../../../img/x.svg";
import Tag from "../../tag";
import moment from "moment";
import InfoTag from "../../info-tag";
import NoteOutline from '../../../img/icons/noteOutlineThali02.svg';

type ExpandedLogModalState = {
  logTitle: string,
  logContent: string,
  logCreator: string,
  logCreated: string,
  autoGenerated?: boolean
}

const ExpandedLogModal = ({data = {} as ExpandedLogModalState, setClosed, ...props}) => {
  return (
    <Modal {...props} id="ExpandedLogModal" className="Modal" overlayClassName="overlay">
      <div className="expanded-log">
        <div className="expanded-log__header">
          <div className="expanded-log__title-wrapper">
            <h3 className="expanded-log__title">{data.logTitle?.toLowerCase()}</h3>
            <div onClick={() => setClosed()} className="expanded-log__close-wrapper">
              <img src={xIcon} alt="Close" className="expanded-log__close-button" />
            </div>
          </div>
          <div className="expanded-log__header-tags">
            <InfoTag
              icon={NoteOutline}
              iconAltText="Note icon"
              text={data.autoGenerated ? "Auto generated" : "Left By"}
              tag2={!data.autoGenerated ? <Tag type="creme-brulee" text={data.autoGenerated ? 'AUTO' : !data.logCreator ? 'UNKNOWN' : data.logCreator} /> : undefined} />
            <Tag type="date" text={moment(data.logCreated).format('DD/MM/YY HH:mm')} />
          </div>
        </div>

        <div className="expanded-log__content-wrapper">
          <p className="expanded-log__content">{data.logContent}</p>
        </div>
      </div>
    </Modal>
  )
}

export default ExpandedLogModal;
