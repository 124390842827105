import { AxiosRequestConfig } from "axios";
import API from "../api";
import { store } from "../../app/store";

function getStoredToken() {
  return store.getState().auth?.token;
}

/**
 *  Get Token
 *
 * @param {string} username Email address for user
 * @param {string} password Password for user
 * @param requestConfig
 * @function
 * @returns
 */
export async function generateToken(username: string, password: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const tokenRes = await API.Instance.post("/auth/login", JSON.stringify({ username, password}), requestConfig);

    if (!tokenRes.data || tokenRes.data.error) return 'error';
    var authToken = tokenRes.data.data.token;

    const userDataRes = await API.Instance.get("/auth/user/info", {...requestConfig, headers: { Authorization: authToken }});

    if (!userDataRes.data || userDataRes.data.error) return 'error';
    return {'userData': userDataRes.data.data, 'token': authToken}
}

/**
 *  Admin login
 *
 * @param {string} username Email address for user
 * @param {string} password Password for user
 * @param requestConfig
 * @function
 * @returns
 */
// export async function loginAdmin(username: string, password: string, requestConfig?: AxiosRequestConfig): Promise<User | boolean> {
//     const res = await API.Instance.post("/admin/website-data/login", JSON.stringify({ username, password }), requestConfig);

//     if (res.data.data === false) return false;
//     else return res.data.data as User;
// }

/**
 *  Refresh Auth Token
 *
 * @param {string} token pass auth token
 * @param requestConfig
 * @function
 * @returns
 */
export async function refreshToken(requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.get("/auth/refresh", {...requestConfig, headers: { Authorization: getStoredToken() }});

    if (!res.data || res.data.error) return false;
    return res.data.data.token;
}

/**
 * Get Agent's Shops
 * @param {string} user_id Agent's user ID
 * @param requestConfig
 * @returns {AdminShop} Array of shops
 * @function
 */
export async function getShopsByAgent(user_id: string, requestConfig?: AxiosRequestConfig): Promise<AdminShop[]> {
    const res = await API.Instance.get("/admin/website-data/shops/generic", {
        ...requestConfig,
        params: {
            rawJson: `{"user_id":${user_id}}`
        },
        headers: { Authorization: getStoredToken() },
    });
    return res.data.data;
}

/**
 * Get Shops this agent manages (including their own shops)
 * @param {string} user_id Agent's user ID
 * @param requestConfig
 * @returns {AdminShop} Array of shops
 * @function
 */
export async function getShopsManagedByAgent(user_id: string, requestConfig?: AxiosRequestConfig): Promise<AdminShop[]> {
    const res = await API.Instance.get("/admin/website-data/shops/generic", {
        ...requestConfig,
        params: {
            rawJson: `{"user_id":${user_id},"managed_by":${user_id}}`
        },
        headers: { Authorization: getStoredToken() },
    });
    return res.data.data;
}

/**
 * Check user is permitted
 * @param {string} user_id User's ID
 * @param requestConfig
 * @returns {boolean} is user permitted?
 * @function
 */
export async function isPermitted(user_id: string, requestConfig?: AxiosRequestConfig): Promise<boolean> {
    const res = await API.Instance.post("/admin/website-data/permitted", { user_id }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data === 1;
}


/**
 * Get Shop Overview
 * @param {string} shop_id ID of the Shop
 * @param requestConfig
 * @returns
 * @function
 */
export async function getShopOverview(shop_id: string, requestConfig?: AxiosRequestConfig): Promise<ShopOverview | false> {
    try {
        const data = await API.Instance.get(`/takeaway/secure/${shop_id}`, {...requestConfig, headers: { Authorization: getStoredToken() }});
        return data.data as ShopOverview;
    } catch (err) {
        return false;
    }
}


/**
 * Get Shops Transactions
 * @param {string} wholesaler_id ID of the Shop
 * @param type
 * @param requestConfig
 * @returns
 * @function
 */
 export async function getShopHistory(wholesaler_id: string, type: string, requestConfig?: AxiosRequestConfig): Promise<ShopTransaction[] | ShopJob[] | ShopOrder[]> {
    const res = await API.Instance.post(`/admin/website-data/shop/history`, { wholesaler_id, type }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    if (type === "logs-and-jobs") return res.data.data[0] as ShopJob[];
    else if (type === "orders") return res.data.data[0] as ShopOrder[];
    else if (type === "transactions") return res.data.data[0] as ShopTransaction[];
    else return [];
}

/**
 * Get shop platform month data
 * @param {string} wholesaler_id ID of the Shop
 * @param requestConfig
 * @returns
 * @function
 */
export async function getShopPlatform(wholesaler_id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.post(`/admin/website-data/shop/platform`, { wholesaler_id }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data;
}

/**
 * Get shop monthly data
 * @param {string} wholesaler_id ID of the Shop
 * @param requestConfig
 * @returns
 * @function
 */
export async function getMonthlyShopData(wholesaler_id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.get(`/internal/ranks/${wholesaler_id}`, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res;
}

/**
 * Get logs for a specific job
 * @param {string} job_id ID of the Job
 * @param requestConfig
 * @returns
 * @function
 */
export async function getJobLogs(job_id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.post("/admin/website-data/shop/get-job-logs", { job_id }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data;
}

/**
 * Get staff devices for a shop
 * @param {string} wholesaler_id ID of the Shop
 * @param requestConfig
 * @returns
 * @function
 */
export async function getShopStaffDevices(wholesaler_id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.post("/admin/website-data/shop/staff-devices", {wholesaler_id}, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data;
}

/**
 * Send machine test
 *
 * @param {string} shop_id ID of the Shop
 * @param {"push" | "offer" | "order"} type Type of machine test to send
 * @param requestConfig
 * @returns {boolean}
 * @function
 */
export async function sendTest(shop_id: string, type: string, requestConfig?: AxiosRequestConfig): Promise<boolean> {
    const res = await API.Instance.post("/admin/website-data/shops/send-test", { shop_id, type }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    if (res?.data.data.status === "Ok") return true;
    return false;
}

/**
 * Add a job
 *
 * @param {string} shop_id ID of the shop
 * @param {string} reason
 * @param {string} description
 * @param {number} assigned_to User ID of user adding job/log
 * @param {number | null} notifiy_stakeholders User can choose whether to receive updates
 * @param {string} priority
 * @param job_id
 * @param requestConfig
 * @returns
 * @function
 */
export async function addJob(shop_id: string, reason: string, description: string, assigned_to: number | null, notifiy_stakeholders: number | null, priority: string, job_id: string = "", requestConfig?: AxiosRequestConfig): Promise<boolean> {
    let res;
    if (job_id === "") {
        res = await API.Instance.post("/admin/website-data/shops/add-job-log",  { shop_id, type:'job', reason, description, assigned_to, notifiy_stakeholders, priority }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    }
    else { 
        const data: { assigned_to?: number; priority?: string } = {};
    
        if (assigned_to !== null) data.assigned_to = assigned_to;
        if (priority) data.priority = priority;
    
        res = await API.Instance.post(`/admin/website-data/shops/${job_id}/update-job-add-log`, data, {...requestConfig, headers: { Authorization: getStoredToken() }});
    }

    if ((res.data.data.status ?? "Error") === "Ok") return true;
    else return false;
}

/**
 * Add a log to an existing job
 *
 * @param {string} shop_id ID of the shop
 * @param {"log"} type Type of log to add (fixed)
 * @param {string} reason
 * @param {string} description
 * @param job_id
 * @param requestConfig
 * @returns
 * @function
 */
export async function addLog(shop_id: string, type: string, reason: string, description: string, job_id: string = "", requestConfig?: AxiosRequestConfig): Promise<boolean> {
    let res;
    if (job_id !== "") {
        res = await API.Instance.post("/admin/website-data/shops/add-job-log", { shop_id, type, reason, description, job_id }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    }
    else {
        res = await API.Instance.post("/admin/website-data/shops/add-job-log", { shop_id, type, reason, description }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    }

    if ((res.data.data.status ?? "Error") === "Ok") return true;
    else return false;
}

/**
 * Get shop performance data
 * @param {string} agent_id ID of the agent to get data for
 * @param requestConfig
 * @returns
 * @function
 */
export async function getShopPerformance(agent_id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.get(`/admin/website-data/agent/${agent_id}/shop-performance`, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data;
}

/**
 * Get agent shop checklist data
 * @param {string} agent_id ID of the agent to get data for
 * @param requestConfig
 * @returns
 * @function
 */
export async function getShopChecklist(agent_id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.get(`/admin/website-data/agent/${agent_id}/shop-checklist`, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data;
}

/**
 * Get agent targets data
 * @param {string} agent_id ID of the agent to get data for
 * @param requestConfig
 * @returns
 * @function
 */
export async function getAgentTargets(agent_id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.get(`/admin/website-data/agent/${agent_id}/targets`, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data;
}

// /**
//  * Get jobs by Agent name
//  * @param {string} name Name of the agent
//  * @param requestConfig
//  * @returns
//  * @function
//  */
// export async function getAgentJobs(name: string, requestConfig?: AxiosRequestConfig): Promise< as Job[]> {
//     const res = await API.Instance.get(`/agent/jobsbytype/${name}`, {...requestConfig, headers: { Authorization: getStoredToken() }});
//     return res.data as Job[];
// }

/**
 * Get jobs - New endpoint
 * @param requestConfig
 * @returns
 * @function
 */
export async function getAgentJobs(requestConfig?: AxiosRequestConfig): Promise<Job[]> {
  const res = await API.Instance.get('/agent/jobs', {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data as Job[];
}


/**
 * Get the sales report for Matt
 * @param form Form data to submit - dates
 * @param requestConfig
 * @returns
 * @function
 */
export async function getSalesReport(form: FormData, requestConfig?: AxiosRequestConfig): Promise<any> {
    const urlParams:any = [];

    // Convert the form data to url
    // (this used to be a post request, but post requests are not cacheable, so converted)
    form.forEach((value, key) => {
        urlParams.push(`${key}=${encodeURIComponent(value.toString())}`);
    });

    const url = '/reporting/areas/secure?' + urlParams.join('&');
    const res = await API.Instance.get(url, {...requestConfig, headers: { Authorization: getStoredToken() }});

    return res.data;
}

/**
 * Marks a job as complete
 * @param jobId 
 * @param description 
 * @param requestConfig 
 * @returns 
 * @function
 */
export async function markJobComplete(jobId: string, description: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.post(`/agent/completed/${jobId}`, { description }, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data;
}

/**
 * Create new Shop
 * @param {CreateShopFormType} form - Object containing the form data
 * @param requestConfig
 * @returns
 * @function
 */
export async function createShop(form: FormData, requestConfig?: AxiosRequestConfig): Promise<CreateShopResultType> {
    const res = await API.Instance.post("/takeaway/create", form, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data;
}

/**
 * Get the Menu of a shop
 * @param id - ID of the shop
 * @param requestConfig
 * @returns
 * @function
 */
export async function getShopMenu(id: string, requestConfig?: AxiosRequestConfig): Promise<MenuCategory[]> {
    const res = await API.Instance.get(`/admin/${id}/menu`, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data[0].categories;
}

/**
 * Update a shops menu
 * @param shopId ID of the shop
 * @param productId ID of the product
 * @param form Values to update
 * @param requestConfig
 */
export async function updateShopMenu(shopId: string, productId: string, form: any, requestConfig?: AxiosRequestConfig): Promise<boolean> {
    const res = await API.Instance.put(`/admin/${shopId}/menu/product/${productId}`, form, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data[0].updated;
}

/**
 * Get inventory device
 * @param id Serial number of device
 * @param requestConfig
 * @returns
 * @function
 */
export async function getInventoryDevice(id: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.get(`/admin/inventory/${id}`, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data;
}

/**
 * Add new device
 * @param form - Object containing the form data
 * @param requestConfig
 * @returns
 * @function
 */
export async function addNewDevice(form: FormData, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.post("/admin/inventory", form, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data;
}

/**
 * Add inventory log
 * @param form - Object containing the form data
 * @param requestConfig
 * @returns
 * @function
 */
export async function addInventoryLog(form: FormData, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.post("/admin/inventory/location", form, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data;
}

/**
 * Add pinned status for shop log
 * @param {number} logId
 * @param requestConfig
 * @returns
 * @function
 */
export async function toggleLogPin(logId: string, requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.put(`/admin/website-data/logs/togglepinned/${logId}`, {}, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data;
}

/**
 * Get users favourites shops
 * @param requestConfig
 * @returns
 * @function
 */
export async function getFavourites(requestConfig?: AxiosRequestConfig): Promise<any> {
    const res = await API.Instance.get(`/internal/user/favourites`, {...requestConfig, headers: { Authorization: getStoredToken() }});
    return res.data.data;
}

/**
 * Add shop to favourites
 * @param {string} shopId ID of the favourited shop
 * @param requestConfig
 * @returns
 * @function
 */
export async function addFavourite(shopId: string, requestConfig?: AxiosRequestConfig): Promise<boolean> {
  const res = await API.Instance.post(`/internal/user/favourites/${shopId}`, {}, {...requestConfig, headers: { Authorization: `Bearer ${getStoredToken()}` }});
  return res.data.data;
}

/**
 * Remove shop from favourites
 * @param {string} shopId ID of the favourited shop
 * @param requestConfig
 * @returns
 * @function
 */
export async function removeFavourite(shopId: string, requestConfig?: AxiosRequestConfig): Promise<boolean> {
  const res = await API.Instance.delete(`/internal/user/favourites/${shopId}`, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data;
}

/**
 * Get all prospects
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function getAllProspects(requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.get(`/internal/prospects/all`, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data;
}

/**
 * Get prospects assigned to user
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function getMyProspects(requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.get(`/internal/prospects`, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data;
}

/**
 * Get single prospect
 * @param {number} userId ID of the user
 * @param {number} prospectId ID of the prospect
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function getProspect(userId: number, prospectId: number, requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.get(`/internal/prospects/${userId}/${prospectId}`, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data;
}

/**
 * Update prospect
 * @param {string} prospectId ID of the prospect being updated
 * @param prospectData Object containing data to be updated
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function updateProspect(prospectId: string, prospectData: any, requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.put(`/internal/prospects/${prospectId}`, prospectData, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res;
}

/**
 * Create prospect
 * @param prospectData Object containing new prospect data
 * @param requestConfig
 * @returns
 * @function
 */
export async function addNewProspect(prospectData: any, requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.post(`/internal/prospects`, prospectData, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data;
}

/**
 * Create interaction
 * @param {string} prospectId ID of the prospect being updated
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function createInteraction(prospectId: string, interactionData: any, requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.post(`/internal/prospects/${prospectId}/interactions`, interactionData, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data;
}
// notes visit - optional - userid prospect reasonid lat lng

/**
 * Update interaction
 * @param {string} prospectId ID of the prospect being updated
 * @param {string} interactionId ID of the interaction being updated
 * @param interactionData Object containing data to be updated
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function updateInteraction(prospectId: string, interactionId: any, interactionData: any, requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.post(`/internal/prospects/${prospectId}/interactions/${interactionId}`, interactionData, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res;
}

/**
 * Get staff members
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function getStaff(requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.get(`/internal/user?grouped=true`, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data;
}

/**
 * Get agent overview data
 * @param requestConfig
 * @returns
 * @function
 */
// any below?
export async function getAgentOverviewData(requestConfig?: AxiosRequestConfig): Promise<any> {
  const res = await API.Instance.get(`/admin/website-data/shops/generic/all`, {...requestConfig, headers: { Authorization: getStoredToken() }});
  return res.data.data;
}

/** @see {isAdminShop} ts-auto-guard:type-guard */
export interface AdminShop {
    wholesaler_id: number;
    wholesaler_name: string;
    wholesaler_postcode: string;
    info: AdminShop_Info;
    numbers: AdminShop_Numbers;
    orders: AdminShop_Orders;
    sms: AdminShop_Sms;
    promotion: AdminShop_Promotion;
    machine: AdminShop_Machine;
    marketing: AdminShop_Marketing;
}

export interface AdminShop_Marketing {
 logs: Array<MarketingLog>
}


export interface AdminShop_Machine {
    user_id: number;
    password: number;
    type: string;
    connection: string;
}

export interface AdminShop_Info {
    wholesaler_status: string;
    label: string;
    signed_by: string;
    commission: string;
    sign_up_date: string;
    online_date: string;
    customers: number;
    email_address: string;
    contact_name: string;
    account_balance: string;
    package_type: string;
}
export interface AdminShop_Numbers {
    last_7_days: number;
    this_month: number;
    last_month: number;
}
export interface AdminShop_Orders {
    total: number;
    today: number;
    this_week: number;
    this_month: number;
    last_month: number;
    last_checked: string;
    prev_month: number;
    prev_prev_month: number;
}
export interface AdminShop_Sms {
    balance: number;
    today: number;
    this_week: number;
    this_month: number;
    last_month: number;
    last_campaign?: any;
}
export interface AdminShop_Promotion {
    facebook: boolean;
    google: boolean;
    stickers: boolean;
}

export interface CreateShopFormType {
    name: string;
    mobile: string;
    mobile2: string;
    region: string;
    ownerName: string;
    postcode: string;
    landline: string;
    email: string;
    address: string;
    trial: string;
    logo: "paper" | "justeat";
    menu: "paper" | "justeat";
    domain: string;
    agent: string;
    availableCcs: string;
    user: string;
    accountType: string;
    activatedBy: string;
    machineDeposit: string;
    liteShop: string;
    brands: string;
}

/** @see {isCreateResult} ts-auto-guard:type-guard */
export interface CreateShopResultType {
    fmoId: string;
    ccId: string;
    password: string;
}

export interface OpeningTimesDay {
    opening_time_start: string;
    opening_time_end: string;
}

export interface OpeningTimes {
    sun: OpeningTimesDay[];
    mon: OpeningTimesDay[];
    tues: OpeningTimesDay[];
    wed: OpeningTimesDay[];
    thurs: OpeningTimesDay[];
    fri: OpeningTimesDay[];
    sat: OpeningTimesDay[];
}

export interface DeliveryTimesDay {
    delivery_time_start: string;
    delivery_time_end: string;
}

export interface DeliveryTimes {
    wed: DeliveryTimesDay[];
    thurs: DeliveryTimesDay[];
    fri: DeliveryTimesDay[];
    sat: DeliveryTimesDay[];
    sun: DeliveryTimesDay[];
    tues: DeliveryTimesDay[];
    mon: DeliveryTimesDay[];
}

export interface Review {
    reviews_id: string;
    order_id: string;
    user_id: string;
    wholesaler_id: string;
    review_date: string;
    review_name: string;
    review_description: string;
    review_food_quality: string;
    review_service_quality: string;
    review_delivery_quality: string;
    review_status: string;
    approved_by?: any;
}

export interface DeliveryRadius {
    delivery_radius_id: string;
    delivery_radius_wholesaler_id: string;
    delivery_radius: string;
    delivery_radius_fee: string;
    delivery_radius_min_val: string;
    delivery_radius_min_val_fee: string;
}

export interface CashAndCarry {
    wholesaler_id: string;
    wholesaler_name: string;
    wholesaler_address: string;
    wholesaler_tel: string;
    spent: string;
}

export interface ShopOverview_Promotion {
    facebook: boolean;
    google: boolean;
    stickers: boolean;
}

export interface ShopOverview_Customers {
    total: number;
    total_ordering: number;
}
export interface ShopOverview_Orders {
    // total: number;
    today: number;
    this_week: number;
    this_month: number;
    last_month: number;
    last_checked: any;
    // prev_month: number;
    // prev_prev_month: number;
}

export interface ShopOverview_Sms {
    balance: number;
    today: number;
    // this_week: number;
    // this_month: number;
    // last_month: number;
    last_campaign?: any;
}

export interface ShopOverview_Info {
    // wholesaler_status: string;
    // label: string;
    // signed_by: string;
    // commission: string;
    // sign_up_date: string;
    // online_date: string;
    // customers: number;
    email_address: string;
    contact_name: string;
    account_balance: string;
    // package_type: string;
}
/** @see {isShopOverview} ts-auto-guard:type-guard */
export interface ShopOverview {
    wholesaler_id: string;
    wholesaler_name: string;
    wholesaler_image: string;
    wholesaler_image_btn?: any;
    wholesaler_image_fade?: any;
    wholesaler_address: string;
    wholesaler_postcode: string;
    wholesaler_tel: string;
    wholesaler_fax?: any;
    wholesaler_status: string;
    wholesaler_zone?: any;
    wholesaler_region: string;
    wholesaler_desc?: any;
    wholesaler_min_del: string;
    wholesaler_pos: string;
    wholesaler_slug: string;
    wholesaler_long?: any;
    wholesaler_lat?: any;
    wholesaler_ap: string;
    wholesaler_food: any[];
    wholesaler_halal: string;
    wholesaler_collection: string;
    wholesaler_not_active: string;
    wholesaler_min_del_fee?: any;
    wholesaler_del_fee?: any;
    wholesaler_del_type?: any;
    wholesaler_delivery_lead_time?: any;
    wholesaler_shop_status: string;
    wholesaler_shop_status_datetime: string;
    wholesaler_discount: string;
    wholesaler_mobile: string;
    wholesaler_force_shutdown: string;
    wholesaler_force_restart: string;
    wholesaler_force_refreshpage: string;
    wholesaler_force_logmein: string;
    wholesaler_service_fee: string;
    wholesaler_del_radius: string;
    shop_discount_deadline: string;
    wholesaler_cc_del_day: string;
    menu_sender_sn: string;
    menu_sender_network: string;
    menu_sender_date_activated: string;
    wholesaler_domain_name: string;
    wholesaler_menu_message: string;
    wholesaler_activation_date: string;
    wholesaler_visit_day: string;
    wholesaler_agent_id: string;
    wholesaler_fmo_logo_on_menu: string;
    wholesaler_data_collected: string;
    wholesaler_window_sticker: string;
    wholesaler_mobile_alias: string;
    wholesaler_machine_installed_on: string;
    wholesaler_fmo_lite: string;
    wholesaler_fee: string;
    wholesaler_fmo_only: string;
    wholesaler_ready_to_go: string;
    wholesaler_turned_online: string;
    wholesaler_promoted: string;
    total_data_collected: string;
    sms_1_sent: string;
    sms_2_sent: string;
    sms_3_sent: string;
    wholesaler_data_source: string;
    wholesaler_alergy_info: string;
    wholesaler_cc: string;
    monthly_billing: string;
    wholesaler_activated_by: string;
    marketing_campaign: string;
    wholesaler_hide_address: string;
    block_transfer: string;
    wholesaler_package: string;
    wholesaler_billing: string;
    machine_text_charge: string;
    wholesaler_group_receipt: string;
    wholesaler_sms_order: string;
    wholesaler_master_status: string;
    wholesaler_app_android: string;
    wholesaler_app_iphone: string;
    wholesaler_card_only: string;
    kashflow_id: string;
    wholesaler_tiered_pricing: string;
    wholesaler_created: string;
    wholesaler_selected_cc: string;
    wholesaler_app_link: string;
    wholesaler_no_marketing: string;
    short_code: string;
    background_color: string;
    needs_putting_back_online: string;
    wholesaler_app_receipts_to_print: string;
    wholesaler_app_receipts_to_print_delay: string;
    wholesaler_direct_debit_redirect_id: string;
    wholesaler_session_id: string;
    wholesaler_contactless?: any;
    wholesaler_contactless_amount?: any;
    wholesaler_skip_text_to_pay_charge: string;
    ap_card?: any;
    cheked_for_orders: string;
    fraudulent: string;
    instore_limit: string;
    apply_service_charge: string;
    list_orders: string;
    minimum_collection_amount: string;
    three_months_free: string;
    postcode_alpha?: any;
    wholesaler_metadata: string;
    signed_by?: any;
    preferred_domain?: any;
    logo_type?: any;
    brands?: any;
    menu_type?: any;
    security_word?: any;
    created_on?: any;
    wholesaler_min_del_text: string;
    opening_times: OpeningTimes;
    delivery_times: DeliveryTimes;
    total_reviews: number;
    reviews?: Review[] | null;
    average_rating: number;
    delivery_radius?: DeliveryRadius[] | null;
    isOpenNow: boolean;
    isDeliveringNow: boolean;
    cardFee: number;
    cashFee: number;
    cashFeeText: string;
    cardFeeText: string;
    cash_and_carry: CashAndCarry[];
    wholesaler_rank_name: string;

    cc_account: number;
    cc_password: number;
    user_id: number;
    password: number;
    customers: ShopOverview_Customers;
    // wholesaler_contactname: string;
    // wholesaler_email_address: string;
    promotion: ShopOverview_Promotion;
    orders: ShopOverview_Orders;
    sms: ShopOverview_Sms;
    info: ShopOverview_Info;
}

/** @see {isShopTransaction} ts-auto-guard:type-guard */
export interface ShopTransaction {
    transaction_id: string;
    transaction_timestamp: string;
    transaction_type: string;
    transaction_amount: string;
    class: string;
}

/** @see {isShopOrder} ts-auto-guard:type-guard */
export interface ShopOrder {
    order_id: string;
    order_status: string;
    order_created: string;
    order_user: string;
    order_total_grand: string;
    handover_type: string;
}

/** @see {isShopJob} ts-auto-guard:type-guard */
export interface ShopJob {
    id: string;
    type: string;
    title: string;
    description: string;
    status: string;
    created: string;
    username: string | null;
    pinned: string;
}

/** @see {isJob} ts-auto-guard:type-guard */
export interface Job {
    job_id: string;
    wholesaler_id: string;
    title: string;
    description: string;
    status: string;
    priority: string;
    type: string;
    created: string;
    last_updated: string;
    assigned_to?: any;
    wholesaler_name: string;
    wholesaler_contactname: string;
    wholesaler_lat?: any;
    wholesaler_long?: any;
    wholesaler_address: string;
    wholesaler_tel: string;
    wholesaler_mobile: string;
    agent_first_name?: any;
    agent_last_name?: any;
    postcode: string;
}

export interface MarketingLog {
    marketing_city: string,
    marketing_date: string,
    marketing_event_id: number,
    marketing_notes: string,
    marketing_range: string,
    marketing_recipients: number,
    marketing_type: string,
}

export interface MonthStat {
    month: string,
    rank: string,
    order_count: string,
    year: string,
}

/** @see {isUser} ts-auto-guard:type-guard */
export interface User {
    id: string;
    ip_address: string;
    username: string;
    password: string;
    email: string;
    activation_selector?: any;
    activation_code?: any;
    forgotten_password_selector?: any;
    forgotten_password_code?: any;
    forgotten_password_time?: any;
    remember_selector?: any;
    remember_code?: any;
    created_on: string;
    last_login: string;
    active: string;
    first_name: string;
    last_name?: any;
    company: string;
    phone?: any;
    profile_picture?: any;
    theme?: any;
    regions?: any;
    shares_from: string;
    exclude_postcodes?: any;
    marketing_limit: string;
}

/** @see {isMenuProduct} ts-auto-guard:type-guard */
export interface MenuProduct {
    product_id: string;
    product_num?: string | null;
    product_name: string;
    product_description: string;
    product_price: string;
    product_total_variants: number;
    product_variants?: any;
    product_parent_id: string;
    is_off_menu: boolean;
    options?: any;
    options_price?: string;
    extras?: any;
}

/** @see {isMenuCategory} ts-auto-guard:type-guard */
export interface MenuCategory {
    name: string;
    description: string;
    id: string | number;
    products?: MenuProduct[];
    position?: string;
}
