import { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./index.scss";
import PinFilled from "../../img/mapPinFilled.svg";
import GreenTickFilled from "../../img/greenTickFilled.svg";
import AccountDetails from "../../img/accountDetails.svg"
import Timer from "../../img/timer.svg"
import Tag from "../tag";
import GoogleColour from '../../img/googleColour.svg';
import PinIcon from '../../img/pin.svg';
import Unpin from '../../img/unpin.svg';
import SpeakerIcon from '../../img/speaker.svg'
import InfoTag from "../info-tag"
import {Admin} from "../../fmo-api-sdk";
import { useHistory } from "react-router-dom";
import TextIcon from '../../img/icons/text.svg';
import AddedIconThali02 from "../../img/addedIconThali02.svg";
import NoteOutline from '../../img/icons/noteOutlineThali02.svg';
import RankTag from "../rank-tag";
import logJobData from '../../logJobData';

const TicketCard = ({job, onClick, completed=false, timeline=false, pinned=false, setPinned=() => {}}) => {
  const history = useHistory();
  const navigate = url => history.push(url);
  const ref = useRef(null) as any;
  const [willOpenModal, setWillOpenModal] = useState(false);

  var type;

  if (pinned) {
    type = 'pinned'
  } else if (job.description.includes("Google Business Scraper reported") && job.type === 'marketing') {
    type = 'scraper';
  } else if (job.type === 'marketing' || job.type === 'log') {
    type = job.type;
  } else if (job.title === 'New Shop' || job.type === 'standard funds request' || job.type === 'same day funds request') {
    type = 'log';
  } else {
    type = 'job';
  }

  useEffect(() => {
    setWillOpenModal(ref?.current?.scrollHeight > ref?.current?.clientHeight || type === 'job')
  })

  const getTitleClass = () => {
    if (type === 'job') {
      if (completed) {
        return 'ticket-card__title--job-completed'
      } else {
        return 'ticket-card__title--job'
      }
    } else if (pinned) {
      return 'ticket-card__title--log-pinned'
    }
    return '';
  }

  const validatedTitle = () => {
    if (job.title === '') return 'Note Added to Account';

    var customLogTypes = logJobData['Account Management'].concat(logJobData['Marketing'] as any).filter(logType => typeof logType === 'object') as any;
    var logType = customLogTypes.find(type => type?.value === job.title);

    if (logType) return logType.name;
    return job.title.replace(/_/g, ' ').toLowerCase();
  };

  const togglePinned = async () => {
    await Admin.toggleLogPin(job.id)
      .then(response => {
        console.log('toggle response', response)
        setPinned();
      })
      .catch(error => {
        alert(`Error toggling pinned status: ${error}`);
      });
  }

  const checkAutoGenerated = job => {
    if ((job.username === "0" && job.created_by === null) || [null, 'admin admin'].includes(job.created_by)) return true;
  };

  const trimSurname = fullName => {
    if (fullName) return fullName.substring(0, fullName.indexOf(' '));
  }

  const PassedInfoTag = () => {
    var tag2;
    var tagText;


    if (job.status === 'Completed') {
      tagText = trimSurname(job.completed_by_username ?? job.completed_by) ?? 'Unknown';
    } else {
      tagText = trimSurname(job.created_by_username ?? job.created_by) ?? 'Unknown';
    }

    if (checkAutoGenerated(job)) {
      return <InfoTag icon={NoteOutline} iconAltText="Note icon" text="Auto Generated" />
    } else if (['log', 'pinned', 'marketing'].includes(type)) {
      tag2 = <Tag type="creme-brulee" text={tagText} />
      return <InfoTag icon={NoteOutline} iconAltText="Note icon" text="Left By" tag2={tag2} />
    } else if (type === 'job') {
      tag2 = <Tag type="highlight" text={tagText} />
      if (completed) {
        tag2 = <Tag type="creme-brulee" text={tagText} />
        return <InfoTag icon={GreenTickFilled} iconAltText="Completed icon" text="Completed By" tag2={tag2} />
      }
      return <InfoTag icon={AddedIconThali02} iconAltText="Created icon" text="Created By" tag2={tag2} />
    }
  }

  return (
    <div onClick={willOpenModal ? onClick(job, checkAutoGenerated(job)) : () => {}} className={`ticket-card ticket-card--${type} ${willOpenModal ? 'ticket-card--opens-modal' : ''}`}>
      <div className="ticket-card__header">
        <div>
          {type === 'job' &&
            <>
              <div className="ticket-card__tag-wrapper">
                {completed ? (
                  <Tag text={'Done'} icon={GreenTickFilled} altText="Completed icon" type='completed' />
                ) : (
                  <Tag priority={job.priority.toLowerCase()}/>
                )}

                {timeline ? (
                  !completed && <Tag text='Pending' icon={Timer} altText="Pending icon" type='highlight' />
                ) : (
                  <>
                    <RankTag rank={job.wholesaler_rank_name} square />
                    <Tag text={job.postcode} icon={PinFilled} altText="Pin icon" type='blueberry' />
                  </>
                )}
              </div>
            </>
          }

          {type === 'log' && <Tag text="LOG" type='eel' />}

          {type === 'pinned' && <Tag text="PINNED LOG" type='fmo' />}

          {type === 'marketing' && <Tag text="MARKETING" type='highlight' icon={SpeakerIcon} altText="Marketing icon" />}

          {type === 'scraper' && <Tag text="SCRAPER" icon={GoogleColour} altText="Google scraper" />}

          <h3 className={`ticket-card__title ${getTitleClass()}`}>{timeline ? validatedTitle() : job.wholesaler_name}</h3>
        </div>

        {(type === 'log' || type === 'pinned') &&
          <div className="ticket-card__button-wrapper ticket-card__button-wrapper--log" onClick={e => {e.stopPropagation(); togglePinned()}}>
            <img src={pinned ? Unpin : PinIcon} alt={pinned ? "Unpin log" : "Pin log"} />
          </div>
        }

        {type === 'job' &&
          (
            timeline ? (
              <Tag text={job.total_logs ?? 0} type="primary" icon={TextIcon} altText="Related logs icon"/>
            ) : (
              <div onClick={() => navigate(`/overview/${job.wholesaler_id}`)} className="ticket-card__button-wrapper">
                <img src={AccountDetails} alt="Open job" />
              </div>
            )
          )
        }
      </div>

      <p ref={ref} className={`ticket-card__description ${!timeline ? 'ticket-card__description--my-jobs' : ''}`}>{job.description}</p>
      <div className={`ticket-card__footer ${!timeline ? 'ticket-card__footer--my-jobs' : ''}`}>

        {PassedInfoTag()}

        <div className="ticket-card__footer-right">
          <Tag text={moment(job.created).format('DD/MM/YY HH:mm')} type="date" />
          {type === 'job' && !timeline && (
            <Tag text={job.total_logs ?? 0} type="primary" icon={TextIcon} altText="Related logs icon"/>
          )}
        </div>
      </div>
    </div>
  )
}

export default TicketCard;