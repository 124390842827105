import {Admin} from '../../../fmo-api-sdk';
import {useState} from "react";
import Modal from "react-modal";
import ModalHeader from '../../modal-components/modal-header';
import ModalDropdown from "../../modal-components/modal-dropdown";
import ModalTextArea from "../../modal-components/modal-text-area";
import Button from "../../button";
import Tag from '../../tag';
import moment from 'moment';
import logJobData from '../../../logJobData'

type AddLogModalState = {
    jobId: string,
    shopId: string,
    shopName: string
}

const AddLogModal = ({data = {} as AddLogModalState, setClosed, jobHistory=false, isOpen=false}) => {

    const [reason, setReason] = useState('');
    const [description, setDescription] = useState('');
    const [validationErrors, setValidationErrors] = useState<Array<string>>([]);

    const isMobile = window.innerWidth < 768;

    const addLog = async(onComplete, finalReason) => {
      await Admin.addLog(data.shopId, "log", finalReason, description, data.jobId)
        .then(onComplete)
        .catch(res => {
          alert('There was a problem submitting this log, please try again.')
        });
    }

    const submitLog = async (): Promise<void> => {
      setValidationErrors([]);
      var tempErrors = [] as string[];
          
      var finalReason = reason;
      
      if (jobHistory) {
        finalReason = "Job Log Added";
        setReason(finalReason);
      }

      if (!reason && !jobHistory) {
        tempErrors.push('issue');
      }
      if (!description) {
        tempErrors.push('description');
      }

      if (tempErrors.length) {
        setValidationErrors(tempErrors);
      } else {
        addLog(() => handleCloseModal(true), finalReason);
      }
    }

    const handleCloseModal = (forceUpdate = false) => {
      setReason('');
      setDescription('');
      setValidationErrors([]);
      setClosed(forceUpdate);
    }

    const completeJob = async () => {
      await Admin.markJobComplete(data.jobId, description)
        .then(() => handleCloseModal(true))
        .catch(res => {
          alert('There was a problem completing this job, please try again.')
        });
    }

    const headerTags = () => (
      <>
        <Tag text={data.shopName} size="large" type="primary" />
        {!isMobile && <Tag text={moment().format('DD/MM/YY HH:mm')} size="large" type="date" />}
      </>
    )

    const modalContent = () => (
      <div className="modal__content modal-scrollbar">
        {!jobHistory &&
          <ModalDropdown
            title="Nature of Issue"
            description="What does the log relate to?"
            required
            categories={logJobData}
            selectedOption={reason}
            onClick={setReason}
            error={validationErrors.includes('issue')} />
        }
        <ModalTextArea
          value={description}
          title="Notes"
          required
          onChange={ev => setDescription(ev.target.value)}
          error={validationErrors.includes('description')}
          filled={Boolean(description)}
          noHeader={jobHistory}
          errorMessage='You can’t submit a log without any text.'
          customStyles={{mobile: {padding: 24}, desktop: {paddingTop: 32, paddingBottom: 32}}} />

        <div className="modal__submit-wrapper">
          <Button
            type="success"
            buttonText={jobHistory ? 'Submit Log' : 'Submit'}
            onClick={() => submitLog()}
            grow
            disabled={validationErrors.length && ((!jobHistory && !reason) || !description) ? true : false} />
          {jobHistory &&
            <Button
              type="primary"
              buttonText="Complete Job"
              onClick={() => completeJob()}
              grow />}
        </div>
      </div>
    );

    return (
      <>
        {jobHistory ? (
          modalContent()
        ) : (
          <Modal isOpen={isOpen} className="modal" overlayClassName="overlay">
            <ModalHeader title="Log Entry" setClosed={handleCloseModal} tags={headerTags} />
            {modalContent()}
          </Modal>
        )}
      </>
    )
}

export default AddLogModal;
