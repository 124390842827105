import React, { Dispatch, SetStateAction, useState } from 'react';
import './index.scss';
import caret from '../../../img/icons/caret.svg'
import UserInputHeader from '../../user-input-header';
import Tag from '../../tag';
import ModalOptions from '../modal-options';

type Props = {
  title: string,
  description: string,
  categories: any,
  selectedOption: any,
  onClick: Dispatch<SetStateAction<any>>
  names?: boolean
} & (
  {
    required: boolean
    error: boolean
  } | {
    required?: never
    error?: never
  }
)

const ModalDropdown = ({title, description, required=false, categories, selectedOption, onClick, error=false, names=false}: Props) => {
  const [activeCategory, setActiveCategory] = useState('');
  const [selectedFromCategory, setSelectedFromCategory] = useState('');

  const handleOnClick = (option) => {
    setActiveCategory('');
    setSelectedFromCategory(activeCategory);
    onClick(option);
  }

  const getSelectedTag = () => {
    var selectedObject = categories[selectedFromCategory]?.find(option => option.value === selectedOption);
    if (names) var firstName = selectedObject?.name.split(" ")[0];

    if (selectedObject || categories[selectedFromCategory].includes(selectedOption)) return (
      <Tag
        text={(firstName ?? selectedObject?.name ?? selectedOption).toUpperCase()}
        type="creme-brulee"
        size="small"
        customStyles={{maxWidth: window.innerWidth < 768 ? 72 : 104}} />
    )
  }

  return (
    <div> 
      <UserInputHeader title={title} description={description} modal required={required} filled={Boolean(selectedOption)} error={error} />
      {Object.keys(categories).map((cat, index) => (
        <React.Fragment key={index}>
          <div onClick={() => cat === activeCategory ? setActiveCategory('') : setActiveCategory(cat)} className="dropdown-trigger">
            <h3 className="dropdown-trigger__title">{cat}</h3>
            <div className="dropdown-trigger__label-wrapper">
              {selectedFromCategory === cat && getSelectedTag()}
              <img src={caret} alt="Caret icon" className={cat === activeCategory ? 'dropdown-trigger__icon-open' : ''} />
            </div>
          </div>
          
          {cat === activeCategory && <ModalOptions onClick={handleOnClick} listOptions={categories[cat]} selectedOption={selectedFromCategory === cat ? selectedOption : null} />}
        </React.Fragment>
      ))}
    </div>
  )
}

export default ModalDropdown;