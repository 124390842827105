import React, { useRef, useState } from "react";
import "./index.scss";

import {clearShops} from "../../app/slices/shopSlice";
import {logout} from "../../app/slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import logo from "../../img/logo.svg";
import nameActive from "../../img/nameActive.svg";
import nameInactive from "../../img/name.svg";
import postcodeInactive from "../../img/postcode.svg";
import postcodeActive from "../../img/postcodeActive.svg";
import searchIcon from "../../img/search.svg";
import exit from "../../img/exit.svg";

import {menu} from '../../app/menu';
import { getRole, getUserId } from "../../app/auth";
import Button from "../button";

import ArrowLeftIcon from '../../img/arrowLeft.svg'


const NavbarMobile = ({ withFilter, updateSearch, onFilterOpen, back = false, search = false, backFunction, leftHeaderSlot, rightHeaderSlot, hideUser=false}) => {
    const searchRef = useRef<HTMLInputElement>(document.createElement("input"));
    const authData = useSelector((state: any) => state.auth);
    const user = authData?.userData;
    const userRole = getRole(authData?.token);
    const userId = getUserId(authData?.token);

    const dispatch = useDispatch();

    const history = useHistory();

    const navigate = url => {
        history.push(url);
        setToggleBurgerMenu(false);
    }

    const handleModal = () => {
        //@ts-ignore
        // if(window.setModalOpen) {
        //     //@ts-ignore
        //     return window.setModalOpen && window.setModalOpen(true);
        // }
        // else {
            onFilterOpen && onFilterOpen();
        // }
    }

    const [toggleBurgerMenu, setToggleBurgerMenu] = useState(false);
    const [toggleSearchSection, setToggleSearchSection] = useState(false);
    const [filterType, setFilterType] = useState<string>("name");

    const {last_name = ' '} = user;
    const lastNameInitial = (last_name || ' ').substring(0, 1).toUpperCase();

    const doLogout = () => {
        dispatch(clearShops());
        dispatch(logout());
    }

    const handleSearchSection = (e) => {
        e.preventDefault();
        setToggleBurgerMenu(false);
        setToggleSearchSection(!toggleSearchSection);
        searchRef.current.focus();
    }

    const handleBurgerMenu = (e) => {
        e.preventDefault();
        setToggleBurgerMenu(!toggleBurgerMenu);
        setToggleSearchSection(false);
    }

    const handleSearch = () => {
        updateSearch(searchRef.current.value, filterType);
        window.scrollTo(0, 0);
    }

    const linkClick = (route) => {
        navigate(route);
        window.scrollTo(0,0);
    }

    const renderMenu = () => {
        return (
            <ul>
                {menu.map((menuItem, idx) => {
                // @ts-ignore
                    if (menuItem.roles.includes(userRole)) {
                        const {icon, title, route} = menuItem;

                        // Manzoor/David hack
                        if (userRole === 'field') {
                          if ((['132', '229'].includes(userId) && route === '/shops/region') || (!['132', '229'].includes(userId) && route === '/shops/agent-overview')) return null;
                        }

                        return (
                            <li key={idx} onClick={() => linkClick(route)}><img src={icon} alt={title}/>{title}</li>
                        )
                    }
                    return false;
                })}
            </ul>
        )
    }

    return (
        <div className="nav-bar-wrapper">
            <nav className="nav-bar nav-bar-mobile">
                <div className="nav-left">

                    <button className="burgerMenuButton" onClick={handleBurgerMenu}>
                        <div className={"burger-icon " + (toggleBurgerMenu ? "burger-icon-open" : "")}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>

                    <div className="buttonGroup">
                        {withFilter ? <button className="nav-icon-top filter-button" onClick={handleModal} /> : ''}

                        {search ?
                        <button className={"nav-search-button " + (toggleSearchSection ? "nav-search-button-clicked" : "")}
                        onClick={handleSearchSection}></button>
                        : "" }

                        {back ?
                          <Button
                            onClick={backFunction ? () => backFunction() : () => history.goBack()}
                            type="quaternary"
                            icon={ArrowLeftIcon}
                            iconAltText="Go back"
                            condensed
                            customStyles={{marginRight: 'auto'}}
                            variant="square" />
                        : '' }

                        <div className="buttonGroup--left-slot">{leftHeaderSlot}</div>
                        <div className="buttonGroup--right-slot">{rightHeaderSlot}</div>
                    </div>

                </div>

                {user && !hideUser ?
                    <div className="nav-right">
                        <span className="nav-name">{user?.first_name} <span className="nav-surname">{lastNameInitial}.</span></span>
                    </div>
                    : <></>
                }
            </nav>

            <div className={"burgerMenuLinks " + (toggleBurgerMenu ? "burgerMenuActive" : "")}>
                <div onClick={() => navigate("/")}>
                    <img src={logo} width="46" height="46" alt="FMO logo" />
                    <h3><span>CRM</span> Navigation</h3>
                </div>
                {renderMenu()}
            <div onClick={() => doLogout()}><img src={exit} alt="Log out icon"/>Log Out</div>
            </div>

            <div className={"searchSection " + (toggleSearchSection ? "searchSectionActive" : "")}>
                <div className="searchBy">
                    <div className="searchByText">
                        <span>Search the FMO directory</span><br/>
                        <span>using shop name / postcode</span>
                    </div>
                    <div className="searchByIcons">
                        <img className="nav-icon-top" onClick={() => setFilterType("name")} src={filterType === "name" ? nameActive : nameInactive} alt="name" />
                        <img className="nav-icon-top" onClick={() => setFilterType("postcode")} src={filterType === "postcode" ? postcodeActive : postcodeInactive} alt="postcode" />
                    </div>
                </div>
                <div className="inputWrapper">
                    <input className="form-control nav-input" onKeyUp={(key) => { if (key.code === "Enter" || key.keyCode === 13 ) { handleSearch()}}} ref={searchRef} type="text" placeholder={`${withFilter ? 'Filter' : 'Search'} by ${filterType}`} />
                    <button className="nav-search-button" onClick={handleSearch}><img src={searchIcon} alt="search icon" /></button>
                </div>
            </div>

        </div>

    )
}

export default NavbarMobile;
