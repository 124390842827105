import { useEffect, useRef, useState } from 'react';
import './index.scss';
import columnData from './shopListColumns';
import { Admin } from '../../fmo-api-sdk';
import { PuffLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getUserId } from '../../app/auth';
import FavouriteActive from "../../img/favouriteActive.svg";
import FavouriteInactive from "../../img/favouriteInactive.svg";
import NoRankIcon24 from '../../img/icons/rank-icons/no-rank24.svg';
import NewShopIcon24 from '../../img/icons/rank-icons/new-shop24.svg';
import BronzeIcon24 from '../../img/icons/rank-icons/bronze24.svg';
import SilverIcon24 from '../../img/icons/rank-icons/silver24.svg';
import GoldIcon24 from '../../img/icons/rank-icons/gold24.svg';
import PlatinumIcon24 from '../../img/icons/rank-icons/platinum24.svg';
import DiamondIcon24 from '../../img/icons/rank-icons/diamond24.svg';
import EliteIcon24 from '../../img/icons/rank-icons/elite24.svg';
import RankIcon from '../../img/icons/rank-icons/rank-icon.svg';
import moment from 'moment';
import Tag from '../tag';
import SuccessIcon from '../../img/greenTickFilled.svg';
import ErrorIcon from '../../img/icons/statusError.svg';
import ErrorIconFilled from '../../img/icons/statusErrorFilled.svg';
import { useHistory, useLocation } from 'react-router-dom';
import CaretIconWhite from '../../img/icons/caretWhite.svg';
import CaretIconDark from '../../img/icons/caretDark.svg';
import TimerIcon from '../../img/timer.svg';
import PendingIcon from '../../img/icons/statusPending.svg';
import Button from '../button';
import Counter from '../header-components/counter';
import ArrowLeftIcon from '../../img/arrowLeft.svg'
import { orderBy, filter } from "lodash";
import EmptyTable from '../empty-table';
import FilterButtons from '../header-components/filter-buttons';
import ShopListFilterModal from '../modals/shop-list-filter';
import AppliedFilterTag from '../applied-filter-tag';
import StatusFilterIcon from '../../img/icons/applied-filter-icons/shop-status-icon.svg'
import LeverFilterIcon from '../../img/icons/applied-filter-icons/levers-icon.svg'
import SearchButton from '../header-components/search-button';
import SearchField from '../header-components/search-field';
import isProblemShop from '../../app/helper/reports';

interface Props {
  title?: any,
  showRegion?: any,
  setLeftHeaderSlot?: any,
  setRightHeaderSlot?: any,
  setPageTitle?: any
  problemShops?: any
} // look at types here

const AgentShopList = ({title, showRegion, setLeftHeaderSlot, setRightHeaderSlot, setPageTitle, problemShops}: Props) => {
  const history = useHistory();
  const token = useSelector((state: any) => state.auth.token);
  const userId = getUserId(token);
  const ref = useRef<any>(null)
  const {state: passedAgent} = useLocation() as any;

  const [loading, setLoading] = useState(true);
  const [shopData, setShopData] = useState<any>();
  const [favouriteShops, setFavouriteShops] = useState<any>();
  const [visibleSections, setVisibleSections] = useState<string[]>(['orders']);
  const [sortDir, setSortDir] = useState<string>("asc");
  const [sorter, setSorter] = useState('wholesaler_id');
  const [filterOpen, setFilterOpen] = useState(false);
  const [shopFilterData, setShopFilterData] = useState<any>({});
  const [searchFilter, setSearchFilter] = useState(localStorage.getItem('shopListSearch') ?? '');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchOpen, setSearchOpen] = useState(false);

  const isMobile = window.innerWidth < 768;
  const onlineTag = <Tag text='Online' size='large' type='success' icon={SuccessIcon} altText='Success icon' />;
  const offlineTag = <Tag text='Offline' size='large' type='fmo' icon={ErrorIcon} altText='Error icon' />
  const filtersEnabled = Object.values(shopFilterData).find((shop: any) => shop?.length);
  const filtersOrSearchEnabled = searchFilter || filtersEnabled;
  const {deviceFilters, statusFilters, leverFilters, miscFilters, miscHideFilters} = shopFilterData;

  const miscData = [
    {name: 'Favourites', value: 'favourites'},
    {name: 'Higher Orders (Last Month)', value: 'higher_orders'},
    {name: 'New Signups', value: 'new_signups'},
    {name: 'Old Signups (12 Months+)', value: 'old_signups'}
  ];

  const leverData = [
    {name: 'G Listing', value: 'google_business'},
    {name: 'Branding', value: 'stickers'},
    {name: 'SMS Sent', value: 'sms'},
    {name: 'Flyers', value: 'flyers'},
    {name: 'ePOS Primary', value: 'is_epos_primary'},
    {name: 'Posters', value: 'posters'},
    {name: 'FB Page', value: 'social'},
    {name: 'AP Card', value: 'has_ap_card'},
    {name: 'Menu Link', value: 'menu_link'},
    {name: 'PPC', value: 'ppc'},
    {name: 'Email', value: 'email'},
    {name: 'Push Notifications', value: 'push_notification'},
  ];

  const statusData = [
    {name: 'To Activate', value: '2'},
    {name: 'Online', value: '1'},
    {name: 'Temp Offline', value: '6'},
    {name: 'Offline', value: '0'}
  ];

  // Initial data load
  useEffect(() => {
    setPageTitle && setPageTitle(passedAgent?.name ?? 'My Region')

    var shopFetch = showRegion ? Admin.getShopsManagedByAgent: Admin.getShopsByAgent;

    (async () => {
      await shopFetch(passedAgent?.id ?? userId)
        .then(res => {
          if (problemShops) setShopData(res.filter(shop => isProblemShop(shop)));
          else setShopData(res);
        })
        .catch(error => console.log(error));

      await Admin.getFavourites(userId)
        .then(res => setFavouriteShops(res.shop_favourites))
        .catch(error => console.log(error));

      setLoading(false);
    })()

    var savedFilters = localStorage.getItem('shopListFilters');
    setShopFilterData(savedFilters
      ? JSON.parse(savedFilters)
      : {
        deviceFilters: [],
        statusFilters: [],
        leverFilters: [],
        miscFilters: [],
        miscHideFilters: []
      }
    );
  }, []);

  useEffect(() => {
    if (filtersEnabled) localStorage.setItem('shopListFilters', JSON.stringify(shopFilterData));
    else localStorage.setItem('shopListFilters', '');
  }, [shopFilterData])

  useEffect(() => {
    setLeftHeaderSlot && setLeftHeaderSlot(
      <>
        {passedAgent?.id &&
          <Button
            onClick={() => history.goBack()}
            type="quaternary"
            icon={ArrowLeftIcon}
            iconAltText="Go back"
            condensed
            customStyles={{marginRight: 'auto'}}
            variant={isMobile ? "square" : undefined}
            buttonText={isMobile ? '' : 'Go Back'} />
        }
        <Counter counterData={!filtersOrSearchEnabled || isMobile
          ? filteredData?.length
          : `${filteredData?.length} / ${shopData?.length}`} />
      </>
    );

    setRightHeaderSlot && setRightHeaderSlot(
      <>
        <FilterButtons
          filtersEnabled={filtersOrSearchEnabled}
          openFilterModal={() => setFilterOpen(true)}
          clearFilters={clearFilters}
          disabled={!shopData?.length} />

        {isMobile ?
          <SearchButton onClick={() => setSearchOpen(!searchOpen)} />
          :
          <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or region"
            disabled={!shopData?.length} />
        }
      </>
    )
  }, [shopData, filteredData, searchOpen, searchFilter]);

  // make this work with problem shops
  useEffect(() => {
    if (!loading) ref.current.scrollTop = parseInt(localStorage.getItem('shopListScrollPos') ?? '0');
  }, [loading]);

  useEffect(() => {
    var tempFiltered = shopData;

    // search filtering
    if (searchFilter) tempFiltered = filter(tempFiltered, shop =>
      shop.wholesaler_name?.toLowerCase().includes(searchFilter?.toLowerCase()) ||
      shop.wholesaler_postcode?.toLowerCase().includes(searchFilter?.toLowerCase())
    );
    // device filtering
    if (deviceFilters?.length) tempFiltered = filter(tempFiltered, shop => deviceFilters.includes(getDeviceType(shop.machine.devices)));
    // status filtering
    if (statusFilters?.length) {
      tempFiltered = filter(tempFiltered, shop =>
        ((statusFilters.includes('1') || statusFilters.includes('2')) && statusFilters.includes(shop.info.wholesaler_status)) ||
        (statusFilters.includes('6') && ['6', '8'].includes(shop.info.wholesaler_status)) ||
        (statusFilters.includes('0') && ['0', '5', '7'].includes(shop.info.wholesaler_status))
      );
    }
    // lever filtering
    if (leverFilters?.includes('google_business')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.google_business);
    if (leverFilters?.includes('stickers')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.stickers);
    if (leverFilters?.includes('sms')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.sms);
    if (leverFilters?.includes('flyers')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.flyers);
    if (leverFilters?.includes('is_epos_primary')) tempFiltered = filter(tempFiltered, shop => getDeviceType(shop.machine.devices) === 'T2s' && !shop.marketing.is_epos_primary);
    if (leverFilters?.includes('posters')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.posters);
    if (leverFilters?.includes('social')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.social);
    if (leverFilters?.includes('has_ap_card')) tempFiltered = filter(tempFiltered, shop => !parseInt(shop.marketing.has_ap_card));
    if (leverFilters?.includes('menu_link')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.menu_link);
    if (leverFilters?.includes('ppc')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.ppc);
    if (leverFilters?.includes('email')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.email);
    if (leverFilters?.includes('push_notification')) tempFiltered = filter(tempFiltered, shop => !shop.marketing.push_notification);
    // misc filtering
    if (miscFilters?.length) {
      tempFiltered = filter(tempFiltered, shop =>
        (miscFilters.includes('favourites') && favouriteShops?.includes(shop.wholesaler_id.toString())) ||
        (miscFilters.includes('higher_orders') && shop.orders.last_month > shop.orders.prev_month) ||
        (miscFilters.includes('new_signups') && moment(shop.info.sign_up_date) > moment().subtract(3, 'M')) ||
        (miscFilters.includes('old_signups') && (moment(shop.info.sign_up_date) < moment().subtract(1, 'years') || shop.info.sign_up_date === '0000-00-00'))
      );
    }
    // misc hide filtering
    if (miscHideFilters?.includes('favourites')) tempFiltered = filter(tempFiltered, shop => !favouriteShops?.includes(shop.wholesaler_id.toString()));
    if (miscHideFilters?.includes('higher_orders')) tempFiltered = filter(tempFiltered, shop => !(shop.orders.last_month > shop.orders.prev_month));
    if (miscHideFilters?.includes('new_signups')) tempFiltered = filter(tempFiltered, shop => !(moment(shop.info.sign_up_date) > moment().subtract(3, 'M')));
    if (miscHideFilters?.includes('old_signups')) tempFiltered = filter(tempFiltered, shop => !(moment(shop.info.sign_up_date) < moment().subtract(1, 'years')) && shop.info.sign_up_date !== '0000-00-00');

    setFilteredData(tempFiltered)
  }, [shopData, shopFilterData, searchFilter])

  useEffect(() => {
    if (searchFilter) localStorage.setItem('shopListSearch', searchFilter);
    else localStorage.setItem('shopListSearch', '');
  }, [searchFilter]);

  const handleFavouriteclick = async(event, shopId) => {
    event.stopPropagation();

    if (favouriteShops.includes(shopId)) {
      await Admin.removeFavourite(shopId)
        .then(() => setFavouriteShops(favouriteShops.filter(favourite => favourite !== shopId)))
        .catch(error => alert(error));
    } else {
      await Admin.addFavourite(shopId)
        .then(() => setFavouriteShops(favouriteShops.concat(shopId)))
        .catch(error => alert(error));
    }
  };

  const clearFilters = () => {
    setShopFilterData({
      deviceFilters: [],
      statusFilters: [],
      miscFilters: [],
      miscHideFilters: [],
      leverFilters: []
    });
    setSearchFilter('');
    setFilterOpen(false);
  };

  const handleFilterModalClose = filterData => {
    if (filterData) setShopFilterData(filterData);
    setFilterOpen(false);
  }

  const getDeviceOptions = () => {
    var deviceOptions = [] as any;

    ['T2s', 'V2', 'None'].forEach(device => {
      var regionCount = shopData?.filter(shop => getDeviceType(shop.machine.devices) === device).length;

      deviceOptions.push({
        name: device,
        value: device,
        subValue: regionCount,
        disabled: !regionCount,
      })
    });

    return deviceOptions;
  }

  const getStatusOptions = () => {
    var statusOptions = [] as any;

    statusData.forEach(status => {
      let statusCount;

      if (status.value === '0') statusCount = shopData?.filter(shop => ['0', '5', '7'].includes(shop.info.wholesaler_status)).length;
      else if (status.value === '6') statusCount = shopData?.filter(shop => ['6', '8'].includes(shop.info.wholesaler_status)).length;
      else statusCount = shopData?.filter(shop => shop.info.wholesaler_status === status.value).length;

      statusOptions.push({
        name: status.name,
        value: status.value,
        subValue: statusCount,
        disabled: !statusCount,
      })
    });

    return statusOptions;
  }

  const getLeverOptions = () => {
    var leverOptions = [] as any;

    leverData.forEach(lever => {
      let leverCount;
      if (lever.value === ('is_epos_primary')) leverCount = shopData?.filter(shop => getDeviceType(shop.machine.devices) === 'T2s' && !shop.marketing[lever.value]).length;
      else leverCount = shopData?.filter(shop => !shop.marketing[lever.value]).length;

      leverOptions.push({
        name: lever.name,
        value: lever.value,
        subValue: leverCount,
        disabled: !leverCount,
      })
    });

    return leverOptions;
  }

  const getMiscOptions = (tempMiscFilters) => {
    var miscOptions = [] as any;
    let miscCount;

    miscData.forEach(misc => {
      if (misc.value === 'favourites') miscCount = shopData?.filter(shop => favouriteShops?.includes(shop.wholesaler_id.toString())).length;
      else if (misc.value === 'higher_orders') miscCount = shopData?.filter(shop => shop.orders.last_month > shop.orders.prev_month).length;
      else if (misc.value === 'new_signups') miscCount = shopData?.filter(shop => moment(shop.info.sign_up_date) > moment().subtract(3, 'M')).length;
      else if (misc.value === 'old_signups') miscCount = shopData?.filter(shop => moment(shop.info.sign_up_date) < moment().subtract(1, 'years') || shop.info.sign_up_date === '0000-00-00').length;

      miscOptions.push({
        name: misc.name,
        value: misc.value,
        subValue: miscCount,
        disabled: !miscCount || tempMiscFilters.includes(misc.value),
      })
    });

    return miscOptions;
  }

  const removeFilter = (filterType, removedFilter) => {
    var tempFilterData = {...shopFilterData};
    tempFilterData[filterType] = tempFilterData[filterType].filter(filter => filter !== removedFilter);

    setShopFilterData(tempFilterData);
  }

  const getTableTop = () => {
    if (isMobile) {
      if (filtersOrSearchEnabled && searchOpen) return 192;
      if (filtersOrSearchEnabled) return 144;
      if (searchOpen) return 152;
    } else if (filtersOrSearchEnabled) return 168;
    return 104;
  }

  const getDeviceType = deviceData => {
    if (deviceData?.some(device => device.device_type === 'epos')) return 'T2s'
    else if (deviceData?.some(device => device.device_type === 'smartlink')) return 'V2'
    else return 'None'
  }

  const getRank = (rankName, ordering=false) => {
    if (!rankName) return ordering ? '6' : NewShopIcon24;
    else if (rankName === 'Bronze') return ordering ? '5' : BronzeIcon24;
    else if (rankName === 'Silver') return ordering ? '4' : SilverIcon24;
    else if (rankName === 'Gold') return ordering ? '3' : GoldIcon24;
    else if (rankName === 'Platinum') return ordering ? '2' : PlatinumIcon24;
    else if (rankName === 'Diamond') return ordering ? '1' : DiamondIcon24;
    else if (rankName === 'Elite') return ordering ? '0' : EliteIcon24;
    else return ordering ? '7' : NoRankIcon24;
  }

  const getCallerIdStatus = (shopMachineData, ordering = false) => {
    var lastHeard = shopMachineData?.caller_id_last_heard;

    if (getDeviceType(shopMachineData?.devices) === 'T2s') {
      if (!lastHeard || moment(lastHeard.customer_date_added) < moment().subtract(1, 'M')) return ordering ? 3 : offlineTag;
      else if (lastHeard?.customer_date_added) {
        if (moment(lastHeard.customer_date_added) < moment().subtract(2, 'd')) {
          return ordering ? 2 : <Tag text={moment(lastHeard.customer_date_added).format('DD/MM/YY')} size='large' type='required-default' />
        }
        return ordering ? 1 : onlineTag;
      }
    }

    return ordering ? 4 : 'N/a';
  }

  const getShopStatus = statusCode => {
    if (['6', '8'].includes(statusCode)) return <Tag text="Offline" size='large' type='highlight' icon={TimerIcon} altText='Shop temporarily offline' />
    if (statusCode === '2') return <Tag text="To Activate" size='large' type='stilton' icon={PendingIcon} altText='Shop to be activated' />
    if (['0', '5', '7'].includes(statusCode)) return offlineTag;
    return onlineTag;
  }

  const getBanner = shopStatus => {
    if (['0','2','5','6','7', '8'].includes(shopStatus)) return `shop-list-table__row--${shopStatus}`
    return ''
  }

  const getLeverUptake = (marketingData, hasEpos?) => {
    var leverCount = Object.values(marketingData).filter(lever => !!lever && typeof lever !== 'object').length;

    if (hasEpos !== undefined) return leverCount / (hasEpos ? 12 : 11);
    return leverCount;
  }

  const sortTable = columnRef => {
    if (sorter === columnRef) setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    setSorter(columnRef);
  };

  const sorted = orderBy(filteredData, [shop => {
    let value;

    if (sorter === 'favourite') value = favouriteShops.includes(shop.wholesaler_id.toString()) ? 1 : 0;
    else if (sorter === 'rank') value = getRank(shop.wholesaler_rank_name, true);
    else if (sorter === 'device') value = getDeviceType(shop.machine.devices);
    else if (sorter === 'caller_id') value = getCallerIdStatus(shop.machine, true);
    else if (sorter === 'orders.mom') value = shop?.orders?.last_month - shop?.orders?.prev_month;
    else if (sorter === 'orders.yoy') value = shop.orders.last_month - shop.orders.last_month_last_year;
    else if (sorter === 'marketing.uptake') value = getLeverUptake(shop.marketing, getDeviceType(shop.machine.devices) === 'T2s');
    else if (sorter === 'marketing.is_epos_primary') {
      var deviceType = getDeviceType(shop.machine.devices);
      value = deviceType !== 'T2s' ? -1 : shop.marketing.is_epos_primary;
    }
    else if (sorter.includes('.')) {
      var valRefs = sorter.split('.');
      value = shop[valRefs[0]][valRefs[1]]
    }
    else {value = shop[sorter]};

    // If the values are strings, make the sort case-insensitive
    return typeof value === 'string' ? value.toLowerCase() : value;
  }], [sortDir]);

  const toggleSection = (event, section) => {
    event.stopPropagation();

    if (visibleSections.includes(section)) {
      return setVisibleSections(visibleSections.filter(existingSection => existingSection !== section));
    }
    return setVisibleSections([...visibleSections, section]);
  }

  const getColumnName = columnName => {
    if (columnName === 'This Month') return moment().format('MMM');
    if (columnName === 'Last Month') return moment().subtract(1, 'M').format('MMM');
    if (columnName === 'Prev Month') return moment().subtract(2, 'M').format('MMM');
    if (columnName === 'Prev Prev Month') return moment().subtract(3, 'M').format('MMM');
    if (columnName === 'Last Month Last Year') return moment().subtract(13, 'M').format('MMM YY');
    else return columnName
  }

  const getTrendClass = (orderData, column, cellValue) => {
    let trend;

    if (['MoM', 'YoY'].includes(column)) trend = cellValue;
    else if (column === 'Last Month') trend = orderData?.last_month - orderData?.prev_month;
    else if (column === 'Prev Month') trend = orderData?.prev_month - orderData?.prev_prev_month;
    else if (column === 'Last Month Last Year') {
      if (orderData?.last_month < orderData?.last_month_last_year * 0.75) return 'shop-list-table__cell--warning-dark'
      if (orderData?.last_month > orderData?.last_month_last_year * 1.25) return 'shop-list-table__cell--success-dark'
      trend = orderData?.last_month - orderData?.last_month_last_year;
    };

    if (!trend) return '';
    return trend > 0 ? 'shop-list-table__cell--success' : 'shop-list-table__cell--warning';
  }

  return (
    <>
      <ShopListFilterModal
        isOpen={filterOpen}
        setClosed={handleFilterModalClose}
        deviceOptions={getDeviceOptions()}
        statusOptions={getStatusOptions()}
        leverOptions={getLeverOptions()}
        getMiscOptions={getMiscOptions}
        clearFilters={clearFilters}
        shopFilterData={shopFilterData} />

      {searchOpen && isMobile &&
        <div className='agent-shop-list__search-wrapper'>
          <SearchField
            onSearch={setSearchFilter}
            placeholder="Search name or postcode"
            closeField={() => setSearchOpen(false)}
            disabled={!shopData.length} />
        </div>
      }

      <div
        ref={ref}
        onScroll={() => localStorage.setItem('shopListScrollPos', ref.current.scrollTop)}
        className="agent-shop-list"
        style={{top: getTableTop()}}>

        {filtersOrSearchEnabled &&
          <div className='agent-shop-list__filter-button-wrapper' style={{top: searchOpen && isMobile ? 128 : 80}}>
            {searchFilter &&
              <AppliedFilterTag
                text={searchFilter}
                removeFilter={() => setSearchFilter('')}
                size={isMobile ? 'small' : 'medium'} />}

            {deviceFilters?.map(typeFilter => (
              <AppliedFilterTag
                key={typeFilter}
                text={`Device: ${typeFilter}`}
                removeFilter={() => removeFilter('deviceFilters', typeFilter)}
                size={isMobile ? 'small' : 'medium'} />
            ))}

            {statusFilters?.map(statusFilter => (
              <AppliedFilterTag
                key={statusFilter}
                text={`${statusData.find(status => status.value === statusFilter)?.name}`}
                removeFilter={() => removeFilter('statusFilters', statusFilter)}
                size={isMobile ? 'small' : 'medium'}
                icon={StatusFilterIcon}
                iconAlt='Status filter' />
            ))}

            {miscFilters?.map(miscFilter => (
              <AppliedFilterTag
                key={miscFilter}
                text={`Show All: ${miscData.find(misc => misc.value === miscFilter)?.name}`}
                removeFilter={() => removeFilter('miscFilters', miscFilter)}
                size={isMobile ? 'small' : 'medium'} />
            ))}

            {miscHideFilters?.map(miscHideFilter => (
              <AppliedFilterTag
                key={miscHideFilter}
                text={`Hide All: ${miscData.find(misc => misc.value === miscHideFilter)?.name}`}
                removeFilter={() => removeFilter('miscHideFilters', miscHideFilter)}
                size={isMobile ? 'small' : 'medium'} />
            ))}

            {leverFilters?.map(leverFilter => (
              <AppliedFilterTag
                key={leverFilter}
                text={`No ${leverData.find(lever => lever.value === leverFilter)?.name ?? ''}`}
                removeFilter={() => removeFilter('leverFilters', leverFilter)}
                size={isMobile ? 'small' : 'medium'}
                icon={LeverFilterIcon}
                iconAlt='Lever filter' />
            ))}
          </div>
        }

        <h1 className='agent-shop-list__title'>{title}</h1>

        {loading ? (
            <div className="spinner-border">
                <PuffLoader
                    size={75}
                    color={"#D82B31"}
                    loading={true}
                />
            </div>
          ) : (
            <div className="shop-list-table__wrapper">
              {filteredData?.length ? (
                  <table className="shop-list-table">
                    <thead className="shop-list-table__head">
                      <tr>
                        {columnData.map(column => {
                          if (column.ref) return (
                            column.value.map((subColumn, index) => {

                              var sortRef = subColumn.value === 'customers' ? 'info.customers' : `${column?.ref}.${subColumn.value}`;

                              // renders columns in expandable sections
                              return ((visibleSections.includes(column.ref) || !index) &&
                                <th key={subColumn.value}>
                                  <div
                                    onClick={() => sortTable(sortRef)}
                                    className={`shop-list-table__cell shop-list-table__cell--header shop-list-table__cell--${column.ref} ${!index ? 'shop-list-table__cell--expandable' : ''}`}>

                                    {subColumn.tags?.length &&
                                      <div className={`shop-list-table__toggle-wrapper ${!index ? 'shop-list-table__toggle-wrapper--expandable' : ''}`}>
                                        {subColumn.tags?.length &&
                                          <div className='shop-list-table__tag-wrapper'>
                                            {subColumn.tags.map(tag => {
                                              return <Tag key={tag.tagText} text={tag.tagText} type={tag.tagType ?? undefined} size='x-small' />
                                            })}
                                          </div>
                                        }

                                        {!index &&
                                          <div onClick={e => toggleSection(e, column.ref)} className="shop-list-table__caret-wrapper">
                                            <img
                                              src={['numbers', 'marketing'].includes(column.ref)  ? CaretIconDark : CaretIconWhite}
                                              alt='Hide/show section'
                                              className={`shop-list-table__caret-icon ${!visibleSections.includes(column.ref) ? 'shop-list-table__caret-icon--open' : ''}`} />
                                          </div>
                                        }
                                      </div>
                                    }

                                    <div>{column.ref === 'orders' ? getColumnName(subColumn.name) : subColumn.name}</div>
                                  </div>
                                </th>
                              )
                            })
                          )

                          if (column.value === 'wholesaler_postcode') return null;

                          // below renders all other columns
                          var cellClass = column.value === 'wholesaler_name' ? 'combined' : column.value;

                          return (
                            <th key={column.value as string} className={`shop-list-table__cell-wrapper shop-list-table__cell-wrapper--${cellClass}`}>
                              <div
                                onClick={() => column.value !== 'wholesaler_name' && sortTable(column.value)}
                                className={`shop-list-table__cell shop-list-table__cell--header shop-list-table__cell--${cellClass}`}>
                                {column.value === 'wholesaler_name'
                                  ? (
                                    <>
                                      <div onClick={() => sortTable('wholesaler_name')} className='shop-list-table__link'>Shop Name</div>
                                      {!isMobile && <div onClick={() => sortTable('wholesaler_postcode')} className='shop-list-table__link'>Postcode</div>}
                                    </>
                                  )
                                  : column.value === 'rank'
                                    ? <img src={RankIcon} alt='Rank icon' />
                                    : column.name
                                }
                              </div>
                            </th>
                          )
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      {sorted?.map(shop => {
                        var deviceType = getDeviceType(shop.machine.devices);

                        return (
                          <tr
                            key={shop.wholesaler_id}
                            onClick={() => history.push(`/overview/${shop.wholesaler_id}`)}
                            className={`shop-list-table__row ${getBanner(shop.info.wholesaler_status)}`}>
                            {columnData.map((column: any) => {

                              // collapsible section columns
                              if (column.ref) {
                                var subColumns = Object.values(column.value);

                                return (
                                  subColumns.map((subColumn: any, index) => {
                                    if (visibleSections.includes(column.ref) || !index) {
                                      var subcolumnValue = shop[column.ref][subColumn.value];
                                      var cellContent;
                                      var cellClass;

                                      if (column.ref === 'orders_epos' && subcolumnValue === 0) cellClass = 'shop-list-table__cell--warning';
                                      else if (column.ref === 'orders' && subcolumnValue === 0) cellClass = getTrendClass(shop.orders, subColumn.name, cellContent);
                                      else if (subColumn.value === 'mom') cellContent = shop.orders.last_month - shop.orders.prev_month;
                                      else if (subColumn.value === 'yoy') cellContent = shop.orders.last_month - shop.orders.last_month_last_year;
                                      else if (subColumn.value === 'wholesaler_status') cellContent = getShopStatus(subcolumnValue);
                                      else if (['sign_up_date', 'online_date'].includes(subColumn.value)) cellContent = subcolumnValue === "0000-00-00" ? 'N/a' : moment(subcolumnValue).format('DD/MM/YY');
                                      else if (subColumn.value === 'customers') subcolumnValue = shop.info.customers;
                                      else if (subColumn.value === 'signed_by' && subcolumnValue?.includes('Unknown')) subcolumnValue = subcolumnValue.replace(' (0)', '');
                                        else if (subColumn.value === 'uptake') {
                                        var leverUptake = getLeverUptake(shop.marketing);
                                        cellClass = !leverUptake ? 'shop-list-table__cell--warning' : 'shop-list-table__cell--split'
                                        cellContent = leverUptake ? (
                                          <>
                                            <div>{leverUptake}</div>
                                            <div>{(leverUptake / (deviceType === 'T2s' ? 12 : 11)).toLocaleString(undefined, {style: 'percent', maximumFractionDigits:0})}</div>
                                          </>
                                        )
                                        : '0%';
                                      } else if (column.ref === 'marketing') {
                                        if (subColumn.value === 'is_epos_primary' && deviceType !== 'T2s') cellContent = 'N/a';
                                        else cellContent = <img src={subcolumnValue ? SuccessIcon : ErrorIconFilled} width={24} height={24} alt='Status icon' />;
                                      }

                                      return (
                                        <td key={subColumn.value}><div className={`shop-list-table__cell ${cellClass ?? ''} ${cellContent === 'N/a' ? 'shop-list-table__cell--disabled' : ''}`}>
                                          {cellContent ?? subcolumnValue?.toLocaleString('en-GB')}</div>
                                        </td>
                                      );
                                    }
                                    return null
                                  }
                                ))
                              }

                              var cellContent = shop[column.value];
                              let customClass;
                              let customClickFunc;

                              // name/postcode column
                              if (column.value === 'wholesaler_name') {
                                customClass = 'combined';
                                cellContent = (
                                  <>
                                    <div className='shop-list-table__link'>{shop.wholesaler_name}</div>
                                    {!isMobile && <div className='shop-list-table__link'>{shop.wholesaler_postcode}</div>}
                                  </>
                                )
                              }
                              else if (column.value === 'wholesaler_postcode') return null;

                              // favourites column
                              else if (column.value === 'favourite') {
                                customClickFunc = event => handleFavouriteclick(event, shop.wholesaler_id.toString());
                                cellContent = <img src={favouriteShops.includes(shop.wholesaler_id.toString()) ? FavouriteActive : FavouriteInactive} alt='Favourite icon' />;
                              }

                              // rank column
                              else if (column.value === 'rank') cellContent = <img src={getRank(shop.wholesaler_rank_name)} alt='Shop rank icon' />

                              // device column
                              else if (column.value === 'device') {
                                if (deviceType === 'None') customClass = 'warning';
                                cellContent = deviceType;
                              }

                              // caller id column
                              else if (column.value === 'caller_id') {
                                cellContent = getCallerIdStatus(shop.machine);
                                if (cellContent === 'N/a') customClass = 'disabled';
                              }

                              return (
                                <td key={column.value} onClick={customClickFunc}>
                                  <div className={`shop-list-table__cell ${customClass ? `shop-list-table__cell--${customClass}` : ''}`}>
                                    {cellContent}
                                  </div>
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>

                    <tfoot>
                      <tr>
                        {columnData.map((column: any) => {

                          // renders expandable column footers
                          if (column.ref) {
                            var subColumns = Object.values(column.value);

                            return (
                              subColumns.map((subColumn: any, index) => {
                                let columnSum;
                                let columnPerc;
                                let cellClass;

                                if (subColumn.value === 'mom') columnSum = filteredData.reduce((acc, shop) => acc + (shop.orders.last_month - shop.orders.prev_month), 0)
                                else if (subColumn.value === 'yoy') columnSum = filteredData.reduce((acc, shop) => acc + (shop.orders.last_month - shop.orders.last_month_last_year), 0)
                                else if (column.ref === 'info' || subColumn.value === 'uptake') {
                                  columnSum = 'N/a';
                                  cellClass = 'disabled';
                                }
                                else if (column.ref === 'marketing') {
                                  columnSum = filteredData.reduce((acc, shop) => acc + !!shop[column.ref][subColumn.value], 0);
                                  columnPerc = (columnSum / (subColumn.value === 'is_epos_primary' ? filteredData.filter(shop => getDeviceType(shop.machine.devices) === 'T2s').length : filteredData.length)).toLocaleString(undefined, {style: 'percent', maximumFractionDigits:0});
                                  if (subColumn.value !== 'uptake') cellClass = columnSum === 0 ? 'warning' : 'split';
                                }
                                else if (subColumn.value === 'customers') columnSum = filteredData.reduce((acc, shop) => acc + shop.info.customers, 0);
                                else columnSum = filteredData.reduce((acc, shop) => acc + parseInt(shop[column.ref][subColumn.value]), 0);

                                columnSum = columnSum?.toLocaleString('en-GB')

                                return (visibleSections.includes(column.ref) || !index) && (
                                  <th key={subColumn.value}><div className={`shop-list-table__cell ${cellClass ? `shop-list-table__cell--${cellClass}` : ''}`}>
                                    {column.ref === 'marketing' ? (
                                      <>
                                        {columnSum !== '0' && <div>{columnSum}</div>}
                                        <div>{columnPerc}</div>
                                      </>
                                    ) :
                                      columnSum
                                    }
                                  </div></th>
                                )
                              })
                            )
                          }

                          var cellContent = 'N/a' as any;
                          let customClass;

                          // renders all other column footers
                          if (column.value === 'wholesaler_postcode') return null;
                          else if (column.value === 'wholesaler_name') {
                            cellContent = (
                              <>
                                <div>N/a</div>
                                <div>N/a</div>
                              </>
                            )
                            customClass = 'shop-list-table__cell--combined shop-list-table__cell--disabled';
                          }
                          else if (column.value === 'favourite') cellContent = filteredData.filter(shop => favouriteShops.includes(shop.wholesaler_id.toString())).length;
                          else if (column.value === 'device') cellContent = filteredData.filter(shop => getDeviceType(shop.machine.devices) !== 'None').length?.toLocaleString('en-GB');
                          else if (column.value === 'caller_id') cellContent = `${filteredData.filter(shop => getDeviceType(shop.machine.devices) === 'T2s' && !!shop.machine.caller_id_last_heard?.customer_date_added).length?.toLocaleString('en-GB')} / ${filteredData.length?.toLocaleString('en-GB')}`;

                          return <th key={column.value}><div className={`shop-list-table__cell ${customClass ? customClass : cellContent === 'N/a' ? 'shop-list-table__cell--disabled' : ''}`}>{cellContent}</div></th>
                        })}
                      </tr>
                    </tfoot>
                  </table>
                ) : (
                  <EmptyTable type={!shopData?.length ? 'none-assigned' : 'filter'} tableType='shops' />
                )
              }
            </div>
          )
      }
      </div>
    </>
  )
}

export default AgentShopList;